@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($neutral-0, 0.8);
  z-index: $index-map-loading-overlay;
}
