@import '../../../styles/index';

.base {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.5rem;
  background-color: $surrogate-4;
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  background: var(--project-gradient, #{$gradient-enercity});
  border-radius: 1.3rem;
}
