@import '../../styles/index';

.base {
  align-self: stretch;
  background: $neutral-0;
  border: 0;
  border: 1px solid $neutral-4;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  min-height: 34.75rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 48rem) {
    min-height: 59.5rem;
  }
}
