@import '../../styles/index';

.base {
  border-radius: 0.75rem;
  isolation: isolate;
  overflow: hidden;
  position: relative;

  .map-container {
    min-height: 85vh;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media-from(sm) {
      min-height: 50rem;
    }
  }

  .filter-toggle {
    bottom: 1.5rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    @include media-from(sm) {
      display: none;
    }
  }
}
