@import '../../styles/index';

.base {
  background-color: $neutral-0;
  border-radius: 3px;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  height: 310px;
  max-width: 600px;
  padding: 0.75rem 0.75rem 0;
  transition: transform $duration-default ease;
  width: 100%;

  @include media-from(xs) {
    height: 505px;
    padding: 1rem;
  }

  &:hover {
    transform: translateY(-4px);
  }
}

.video-background {
  align-items: center;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 226px;
  justify-content: center;
  position: relative;
  width: 100%;

  @include media-from(xs) {
    height: 400px;
  }

  &::after {
    background-color: $neutral-12;
    content: ' ';
    height: 100%;
    opacity: 0.06;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.play-button {
  align-items: center;
  background-color: $neutral-0;
  border-radius: 100%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  color: $surrogate-9;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  position: absolute;
  transition: transform $duration-default ease;
  width: 5rem;
  z-index: 2;

  > i {
    height: 30px;
    width: 30px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.thumbnail-picture {
  height: 100%;
  position: absolute;
  width: 100%;
}

.button {
  padding: 1.5rem;

  @include media-from(xs) {
    padding: 1.875rem;
  }

  // override for buttonLink
  button:hover {
    > span,
    > i {
      transform: none !important;
    }
  }
}
